import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { fluidRange } from 'polished';
import { RichText } from 'prismic-reactjs';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Contain from '../components/base/contain';
import Grid from '../components/grid/grid';
import GridCell from '../components/grid/gridCell';
import Panorama from '../components/parts/panoramas/panoramaHome';
import CardHorizontal from '../components/parts/cards/cardHorizontal';
import CardDefault from '../components/parts/cards/cardDefault';
import breakpoints from '../styles/settings/breakpoints';

const IndexPage = ({ data }) => {
    const prismicData = data.prismic.allHomes.edges[0].node;
    const panoramaCarousel = prismicData.panorama_carousel;

    const intro = {
        title: prismicData.intro_title,
        text: prismicData.intro_text
    };

    const groups = [];
    const { group, groups_title } = prismicData;

    const parseGroups = () => {
        if (group) {
            const relatedTitle = groups_title ? groups_title[0].text : 'Gerelateerd';
            for (const [index, { item }] of group.entries()) {
                const card = item;
                if (!card) {
                    return false;
                }

                const metaData = card._meta;
                const linkTags = metaData?.tags;
                const linkTag = linkTags && linkTags.length > 0 ? linkTags[0] : '';
                const linkUid = metaData ? metaData.uid : '';
                const link = `${linkTag}/${linkUid}`;

                groups.push(
                    <CardHorizontal
                        content={{
                            image: card.archive_imageSharp.childImageSharp.fluid,
                            title: card.title[0].text,
                            description: card.description,
                            link: link
                        }}
                        key={index}
                    >
                        {card.title}
                    </CardHorizontal>
                );
            }

            return (
                <SRelated>
                    <Contain>
                        <SRelatedHeading>{relatedTitle}</SRelatedHeading>
                        <SCards>{groups}</SCards>
                    </Contain>
                </SRelated>
            );
        }
        return false;
    };

    const selectedArticles = [];
    const { articles, articles_title } = prismicData;

    const parseArticles = () => {
        if (articles) {
            const relatedTitle = articles_title ? articles_title[0].text : 'Gerelateerde nieuwsberichten';

            for (const [index, { article }] of articles.entries()) {
                const card = article;
                if (!card) {
                    return false;
                }

                const metaData = card._meta;
                const linkUid = metaData ? metaData.uid : '';
                const link = `nieuws/${linkUid}`;

                selectedArticles.push(
                    <GridCell cellSizes={{ small: '100%', medium: '50%', large: `${(1 / 3) * 100}%` }} key={index}>
                        <CardDefault
                            content={{
                                image: card.archive_imageSharp.childImageSharp.fluid,
                                publicationDate: card.publication_date,
                                title: card.title[0].text,
                                description: card.description[0].text,
                                link: link,
                                label: card.label
                            }}
                            key={index}
                        >
                            {card.title}
                        </CardDefault>
                    </GridCell>
                );
            }

            return (
                <SRelated>
                    <Contain>
                        <SRelatedHeading>{relatedTitle}</SRelatedHeading>
                        <Grid gutter="normal">{selectedArticles}</Grid>
                    </Contain>
                </SRelated>
            );
        }
        return false;
    };

    return (
        <Layout>
            <SEO title="Home" />
            <Panorama items={panoramaCarousel} />
            <Contain>
                <SIntro id="intro">
                    <SIntroTitle>{RichText.render(intro.title)}</SIntroTitle>
                    <SIntroText>{RichText.render(intro.text)}</SIntroText>
                </SIntro>
            </Contain>
            {parseGroups()}
            {parseArticles()}
        </Layout>
    );
};

const SIntro = styled.div`
    padding-top: 50px;

    ${breakpoints.large} {
        display: flex;
        padding-top: 100px;
    }
`;

const SIntroTitle = styled.div`
    margin-bottom: 20px;
    width: 100%;
    font-weight: 700;

    h2 {
        ${fluidRange(
            {
                prop: 'font-size',
                fromSize: '20px',
                toSize: '22px'
            },
            '320px',
            '1440px'
        )}
        display: inline;
        position: relative;
        font-weight: 700;
    }

    ${breakpoints.large} {
        margin-bottom: 0;
    }
`;

const SIntroText = styled.div`
    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '16px',
            toSize: '18px'
        },
        '320px',
        '1440px'
    )}
    line-height: 1.5em;

    p + p {
        margin-top: 1em;
    }

    a {
        color: var(--color-secondary);
        text-decoration: underline;
    }

    ${breakpoints.large} {
        margin-left: 40px;
    }
`;

const SCards = styled.div`
    margin-top: 20px;

    ${breakpoints.large} {
        margin-top: 40px;
    }
`;

const SRelated = styled.div`
    padding-top: 80px;
    padding-bottom: 100px;

    & + & {
        padding-top: 20px;
    }
`;

const SRelatedHeading = styled.h2`
    ${fluidRange(
        {
            prop: 'font-size',
            fromSize: '20px',
            toSize: '25px'
        },
        '320px',
        '1440px'
    )}
    font-weight: 700;
`;

export default IndexPage;

export const pageQuery = graphql`
    query {
        prismic {
            allHomes {
                edges {
                    node {
                        panorama_carousel {
                            carousel_title
                            carousel_text
                            button_label
                            button_link {
                                ... on PRISMIC_Single {
                                    _meta {
                                        uid
                                        tags
                                    }
                                }
                                ... on PRISMIC_Team {
                                    _meta {
                                        uid
                                        tags
                                    }
                                }
                                ... on PRISMIC_Archive {
                                    _meta {
                                        uid
                                        tags
                                    }
                                }
                                ... on PRISMIC_Articles {
                                    _meta {
                                        uid
                                        tags
                                    }
                                }
                                ... on PRISMIC_Article {
                                    _meta {
                                        uid
                                        type
                                    }
                                }
                            }
                            carousel_image
                            carousel_imageSharp {
                                childImageSharp {
                                    fluid(quality: 90) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                        intro_title
                        intro_text
                        groups_title
                        group {
                            item {
                                ... on PRISMIC_Single {
                                    title
                                    description
                                    archive_image
                                    archive_imageSharp {
                                        childImageSharp {
                                            fluid(maxWidth: 2560, quality: 90) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                    _meta {
                                        uid
                                        tags
                                    }
                                }
                            }
                        }
                        articles_title
                        articles {
                            article {
                                ... on PRISMIC_Article {
                                    publication_date
                                    title
                                    description
                                    label
                                    archive_image
                                    _meta {
                                        uid
                                    }
                                    archive_imageSharp {
                                        childImageSharp {
                                            fluid(maxWidth: 700, maxHeight: 500, quality: 90) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

IndexPage.propTypes = {
    data: PropTypes.object
};
